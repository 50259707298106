import React from "react";
import '../styles/long1.css';
import '../styles/long2.css';

const long2Page = () => (
  <div class="grid-container">
    <div class="left_space"></div>
    <div class="short1-main">
      <div class="long2-main1 completeprofile-bg-image">
        <div className="logo-div">
          <a aria-current="page" className="logo" href="/">
            <div className="logo-image"></div>
          </a>
        </div>
        <div className="nav-belt register-header-section">
          <div className="wp-block-column grid-column-parent">
            <h3 className="register-header-title">
              Complete your MyTEXpress profile
            </h3>
            <p className="register-header-text">
              You're saving time by using the LBJ, NTE &amp; NTE 35W TEXpress
              Lanes. Find out how much by completing your profile today.
            </p>
          </div>
          <div className="wp-block-column grid-column-children long2-form-section">
            <div
              id="lightbox-inline-form-e63048a9-6f27-423b-8a3e-8b522ed3016c"
              class="register-formBorder"
            ></div>
          </div>
        </div>
      </div>
      <div class="long2-main2">
        <a href="https://www.texpresslanes.com/faq-page" target="_blank">
          <p className="faq">MyTEXpress FAQs</p>
        </a>
      </div>
      <hr className="new4"></hr>
      <div className="long2-main3">
        <p className="register-toll-tag-text">
          All Texas toll transponders are eligible on the TEXpress Lanes as well
          as the Oklahoma PIKEPASS.
        </p>
        <div className="register-box">
          <p className="register-box-text">
            Need help finding your toll transponder number?
          </p>
          <p className="register-box-text">
            Here are two ways you can locate your toll transponder number:
          </p>
          <div className="register-bot-img"></div>
          <p className="register-box-text">
            Refer to the images above to find the information printed on your
            toll transponder{" "}
          </p>
          <p className="register-box-text">
            (on your vehicle's windshield. The toll transponder number is
            printed on the side facing the driver in the vehicle.)
          </p>
          <p className="register-box-text register-box-text-padding">
            You can also find your toll transponder number by logging into your
            respective toll transponder account.{" "}
          </p>
          <div className="register-box-link">
            <a href="https://csc.ntta.org/olcsc/" target="_blank">
              Visit My TollTag Account
            </a>{" "}
            <span className="mobile-line">|</span>{" "}
            <a href="https://www.txtag.org/txtagstorefront/en/" target="_blank">
              Visit My TxTag Account
            </a>{" "}
            <span className="mobile-line">|</span>{" "}
            <a href="https://www.hctra.org/Login" target="_blank">
              Visit My EZ TAG Account
            </a>
          </div>
        </div>
      </div>
    </div>
    <div class="right_space"></div>
    <div class="footer">
      <div class="register-footer1">
        <a href="https://www.facebook.com/TEXpressLanes" target="_blank">
          <img
            class="fb-icon"
            src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7"
          />
        </a>{" "}
        |{" "}
        <a href="https://twitter.com/texpresslanes" target="_blank">
          <img
            class="twitter-icon"
            src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7"
          />
        </a>{" "}
        |{" "}
        <a href="https://www.texpresslanes.com/privacy-policy" target="_blank">
          Privacy Policy
        </a>{" "}
        │ ©2020 LBJ/NTE/NTE 35W TEXpress Lanes | All Rights Reserved
      </div>
    </div>
  </div>
)
export default long2Page;
